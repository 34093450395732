/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Neuton:wght@400;700&family=Spectral:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500&display=swap');

/* Layout */
:root {
    --error: #e7195a;
    --blue: rgb(68, 141, 236);
    --green: rgb(76, 219, 69);
    --pale: #FAF8F5;
    --cream: #EFE9E5;
}
body {
    position: relative;
    font-family: "Spectral";
    background-color: var(--cream);
    margin: 0;
    min-height: 100vh;
    overflow-x: hidden;
}
html {
    scroll-behavior: smooth;
}

/* Navbar */
.navbar {
    background: var(--pale);
    margin: 0 auto;
    padding: 20px 5% 20px 5%;
    display: flex;
    align-items: center;
    width: 100%;
}
.navbar .title {
    text-decoration: none;
    color: #333;
    font-size: 30px;
} 
.navbar .links {
    display: flex;
    margin-left: auto;
    margin-right: 10%;
}
.navbar .links a {
    text-decoration: none;
    color: #333;
    font-size: 24px;
    margin-right: 25px;
}
.navbar .title .logo {
    width: 500px;
}

/* Underline a link from center */
.navbar .links a {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}

.navbar .links a:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0px;
    background: #000000;
    height: 3px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.navbar .links a:hover:before,
.navbar .links a:focus:before,
.navbar .links a:active:before {
    left: 0;
    right: 0;
}

/* Intro photo */
.parallax-container {
    background-image: url('./images/Intro.jpg');
    /* width: 100%; */
    /* z-index: 0; */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Greeting component */
.greeting {
    background-color: var(--cream);
    font-size: 32px;
    line-height: 1.5;
    display: flex;
}
.greet-text {
    padding: 0px 5% 0px 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circular-photo {
    display: inline-block;
    position: relative;
    width: 470px;
    height: 470px;
    margin: 0px 10% 0px 20px;
    object-fit: cover;
    margin-top: -3vh;
    margin-bottom: -3vh;
}
.circular-photo img{
    width: auto;
    height: 100%;
    clip-path: circle();
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

/* Projects Component */
.projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--cream);
    padding: 0 0 16px 0;
}
.projects-header {
    padding: 20px 5% 10px 5%;
    font-size: 50px;
    font-weight: 700;
}
.projects-list {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 90px;
}
.projects-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Neuton', serif;
    background-color: var(--pale);
    height: 70px;
    border: 3px solid var(--cream);
    border-radius: 3px;
    font-size: 32px;
    font-weight: 500;
    margin-top: 20px;
    padding: 40px 30px;
    text-align: center;
    text-decoration: none;
    color: black;

}
.projects-list-item:hover {
    cursor: pointer;
}

/* Link with a shadow grow */
.hover-grow-shadow {
    display: flex;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}

.hover-grow-shadow:hover,
.hover-grow-shadow:focus,
.hover-grow-shadow:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/* Footer */
.footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    text-align: center;
    padding: 7px 0;
    font-size: 20px;
    width: 100%;
    background-color: #1a1b18;
    color: #ffff;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer a {
    margin-left: 8px;
}
.github-image {
    align-items: center;
    width: 32px;
    height: 32px;
    transition: transform 0.3s ease-in-out;
}
.github-image:hover {
    transform: rotate(360deg) scale(1.2);
}

/* In Progress */
.progress {
    font-size: 60px;
    font-weight: 700;
    font-family: 'Spectral';
    text-align: center;
    margin: 40px auto;
}

/* Media queries */
@media screen and (max-width: 1070px) {
    .navbar .links a {
        margin-right: 10px;
        font-size: 20px;
    }
    .projects-list {
        gap: 10px 15px;
    }
    .circular-photo {
        width: 300px;
        height: 300px;
    }
    .greet-text {
        padding: 10% 5% 30px 10%;
    }
    .navbar .title .logo {
        width: 400px;
    }
}

@media screen and (max-width: 930px) {
    .navbar .links a {
        margin-right: 7px;
        font-size: 18px;
    }
    .navbar .title .logo {
        width: 300px;
    }
}

@media screen and (max-width: 700px) {
    .navbar .links a {
        margin-right: 10px;
    }
    .projects-list {
        grid-template-columns: 1fr 1fr;
        gap: 10px 8px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .navbar .title .logo {
        width: 200px;
    }
}